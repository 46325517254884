import React from 'react';
import { Box, Image } from 'grommet';
import { Button } from '@appkit4/react-components/button';
import { navigate } from 'gatsby'
import SidebarRA from '../../components/SidebarRA';

export default function PersonaRA() {
  const onClickButton_RAMyLearning = () => {
    navigate ('/risk-analyst')
  }
  const onClickButton_RAExplore = () => {
    navigate ('/risk-analyst/ra-explore')
  } 
  return (
    <Box
      background={{
      color: '#ffb600'
    }} 
      width= {'100vw'}
      height= {'92vh'}
      direction={'row'}
    >
      <Box pad={{top: '40px', horizontal: "large"}} 
      align={'top'}
      direction={'row'}
      alignContent="between" 
      gap="medium">
        <SidebarRA />
        <Box style={{marginTop: '10px', overflow: 'scroll', borderRadius: '.5em', maxHeight: '90%', maxWidth: '60vw'}}>
          <Box style={{position: 'relative'}}>
            <Image src="https://images.ctfassets.net/fwgo2d14oeir/13H9fPuR34lKaygWEMDKNA/e172508de9f1c68894defea2160eba10/ra-explore.png" fill="horizontal" style={{ position: 'relative', borderRadius: '.5em'}} />
            <Button style={{width: '80px', position: 'absolute', top: '8px', left: '17%'}} kind='text' onClick={onClickButton_RAMyLearning} >&nbsp; &nbsp;<div className='beacon'></div></Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}